<template>
  <v-container fluid class="py-6">
    <v-row class="px-4">
      <v-col lg="6">
          <v-card class="card-shadow border-radius-xl mt-6" id="basic">
              <div class="px-6 py-6">
                  <h5 class="text-h5 font-weight-bold text-typo">Basic Info</h5>
              </div>
              <div class="px-6 pb-6 pt-0">
                  <v-row>
                      <v-col cols="6">
                          <v-text-field
                              color="#e91e63"
                              label="Name"
                              placeholder="Name"
                              class="font-size-input input-style"
                              v-model="profile.name"
                          >
                          </v-text-field>
                          <v-text-field
                              color="#e91e63"
                              label="Email"
                              placeholder="Email"
                              class="font-size-input input-style"
                              v-model="profile.email"
                          >
                          </v-text-field>
                          <v-btn
                              color="#cb0c9f"
                              class="
                                font-weight-bolder
                                btn-default
                                bg-gradient-default
                                py-4
                                px-8
                                ms-auto
                                mt-sm-auto mt-4
                              "
                              small
                              @click="updateProfile()"
                          >
                              Update profile
                          </v-btn>
                      </v-col>
                  </v-row>
              </div>
          </v-card>
      </v-col>
        <v-col lg="6">
            <v-card class="card-shadow border-radius-xl mt-6" id="change">
                <div class="px-6 py-6">
                    <h5 class="text-h5 font-weight-bold text-typo">Change Password</h5>
                </div>
                <div class="px-6 pb-6 pt-0">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Current password"
                                color="#e91e63"
                                outlined
                                dense
                                type="password"
                                class="font-size-input input-style py-0"
                                v-model="password.current"
                            ></v-text-field>
                            <v-text-field
                                label="New password"
                                color="#e91e63"
                                outlined
                                dense
                                type="password"
                                class="font-size-input input-style py-0"
                                v-model="password.new"
                            ></v-text-field>
                            <v-text-field
                                label="Confirm new password"
                                color="#e91e63"
                                outlined
                                dense
                                type="password"
                                class="font-size-input input-style py-0"
                                v-model="password.confirmation"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="d-sm-flex">
                        <v-btn
                            color="#cb0c9f"
                            class="
                                font-weight-bolder
                                btn-default
                                bg-gradient-default
                                py-4
                                px-8
                                ms-auto
                                mt-sm-auto mt-4
                              "
                            small
                            @click="updatePassword()"
                        >
                            Update password
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import axiosIns from '../../plugins/axios'

export default {
  name: "MyProfile",
  components: {},
  data() {
    return {
        password: {
            current: '',
            new: '',
            confirmation: '',
        },
    };
  },
    computed: {
        ...mapState({
            profile: (state) => state.user.profile,
        }),
    },
    methods: {
        updateProfile() {
            axiosIns.post('/api/profile', this.profile)
                .then((response) => {
                    console.log(response);
                    // localStorage.setItem('accessToken', response.data.accessToken);
                    // this.$router.push('/dashboard');
                })
                .catch((error) => {
                    this.$store.commit('app/SNACKBAR', {
                        show: true,
                        body: error.response.data.message,
                        type: 'error',
                    });
                });
        },
        updatePassword() {
            axiosIns.post('/api/profile-password-update', this.password)
                .then((response) => {
                    console.log(response);
                    // localStorage.setItem('accessToken', response.data.accessToken);
                    // this.$router.push('/dashboard');
                })
                .catch((error) => {
                    this.$store.commit('app/SNACKBAR', {
                        show: true,
                        body: error.response.data.message,
                        type: 'error',
                    });
                });
        },
    },
};
</script>
